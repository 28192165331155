import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import { RichText } from 'prismic-reactjs'
import Layout from '../components/layouts'
import htmlSerializer from '../utils/htmlSerializer'

const Page = ({data}) => {
  if (!data) return null
  const document = data.allPrismicCodeItem.edges[0].node

    return (
      <Layout>
        <SEO title={RichText.asText(document.data.title)} />
        <div className="mt-5">

          <div className="page__content">
            <div className="hero__media mx-auto mb-3">
            </div>
          </div>

          <div className="page__content">
            <div class="copy__body__center">
              <div>
                <div className="eyebrow">Design</div>
                <h1 className="display__title mb-5">{RichText.asText(document.data.title)}</h1>
                <div className="description mb-5">
                  {RichText.asText(document.data.description)}
                </div>
              </div>
            </div>
          </div>

        </div>
      </Layout>
    )
  }

// Query for the Blog Post content in Prismic
export const query = graphql`
query CodeQuery($uid: String) {
  allPrismicCodeItem(filter: {uid: {eq: $uid}}) {
    edges {
      node {
        url
        uid
        type
        data {
          title {
            type
            text
          }
          skills {
            type
            text
          }
          link {
            link_type
            url
          }
          featured_image {
            url
          }
          description {
            type
            text
          }
          code_tags {
            code_tag {
              type
              text
            }
          }
        }
        id
      }
    }
  }
}
`

export default Page
